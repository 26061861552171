import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from '@mui/material';
import styles from './CommunityModal.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import { SimpleOptions } from '../../../../components/ui/SimpleOptions/SimpleOptions';
import { TextInput } from '../../../../components/ui/TextInput/TextInput';
import { SelectCommunityMembers } from '../../../../components/ui/SelectCommunityMembers/SelectCommunityMembers';
import { api } from '@lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { startProgress } from '../../../../store/common';
import { fetchRelatedCommunities } from '../../store/related-communities';

export const CommunityModal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const isAdd = !params.communityId;
  const [title, setTitle] = useState(null);
  const [type, setType] = useState('open');
  const [managers, setManagers] = useState([]);
  const pageTitle = isAdd ? 'Новое связанное сообщество' : title;
  const setPageTitle = usePageTitle();
  const dispatch = useDispatch();
  const { id: communityId } = useSelector((state) => state.community.community);
  const user = useSelector((state) => state.auth.user);
  const communities = useSelector(
    (state) => state.relatedCommunities.communities
  );
  const [startManagersIds, setStartManagersIds] = useState([]);
  const [prevManagers, setPrevManagers] = useState([]);

  const closeModal = () => {
    navigate('/club/related/');
  };

  const addCommunity = async () => {
    dispatch(startProgress());
    const { data: item } = await api.post(`/communities/related`, {
      title,
      isPublic: type === 'open',
    });
    for (const manager of managers) {
      const userId = manager.value.userId;
      await api.post(`/communities/related/${item.id}/managers/${userId}`);
    }
    dispatch(fetchRelatedCommunities());
    closeModal();
  };

  const saveCommunity = async () => {
    dispatch(startProgress);
    const newManagers = managers.map((item) => item.value.userId);
    let toDelete = [...prevManagers];
    for (const id of newManagers) {
      toDelete = toDelete.filter((i) => id !== i);

      if (prevManagers.includes(id)) continue;
      await api.post(
        `/communities/related/${params.communityId}/managers/${id}`
      );
    }
    for (const id of toDelete) {
      await api.delete(
        `/communities/related/${params.communityId}/managers/${id}`
      );
    }
    await api.patch(`/communities/related/${params.communityId}`, {
      title,
      isPublic: type === 'open',
    });
    dispatch(fetchRelatedCommunities());
    closeModal();
  };

  const saveButtonClicked = () => {
    if (isAdd) {
      addCommunity();
    } else {
      saveCommunity();
    }
  };

  const updateState = () => {
    const community = communities.find((com) => com.id === +params.communityId);
    if (!community) return;
    setPrevManagers(community.managers.map((manager) => manager.id));
    setTitle(community.title);
    setType(community.isPublic ? 'open' : 'closed');
    setStartManagersIds(community.managers.map((manager) => manager.cardId));
  };

  useEffect(() => {
    dispatch(fetchRelatedCommunities());
    setPageTitle(pageTitle);
  }, []);

  useEffect(() => {
    if (!isAdd) {
      updateState();
    }
  }, [isAdd, communities]);

  return (
    <Dialog open={true} onClose={closeModal} className="wide-dialog">
      <DialogTitle>
        {pageTitle}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ width: '450px', overflowX: 'hidden' }}
        className={styles.modal}
      >
        <div className={styles.description}>
          Назначенный вами менеджер получит доступ к управлению связанным
          сообществом.
        </div>
        <div className={styles.section}>
          <div className={styles.label}>Тип сообщества</div>
          <div className={styles.content}>
            <SimpleOptions
              options={[
                {
                  title: 'Открытое сообщество',
                  name: 'open',
                },
                {
                  title: 'Закрытое сообщество',
                  name: 'closed',
                },
              ]}
              value={type}
              onChange={setType}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.label}>Название</div>
          <div className={styles.content}>
            <FormControl fullWidth>
              <TextInput
                value={title}
                onChange={(ev) => setTitle(ev.target.value)}
                placeholder="Название сообщества"
              />
            </FormControl>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.label}>Менеджеры</div>
          <div className={styles.managers}>
            <SelectCommunityMembers
              multiple
              cardsIds={startManagersIds}
              exclude={(item) => {
                return (
                  managers.map((item) => item.id).includes(item.id) ||
                  user.id === item.userId
                );
              }}
              value={managers}
              onChange={setManagers}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <AppButton color="secondary" onClick={closeModal}>
            Отмена
          </AppButton>
          &nbsp;
          <AppButton
            color="primary"
            onClick={saveButtonClicked}
            disabled={!managers.length}
          >
            {isAdd ? 'Создать' : 'Сохранить'}
          </AppButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
