import { useEffect, useState } from 'react';
import { ResidentAvatar } from '../../../features/club/components/ResidentAvatar/ResidentAvatar';
import { AppSelect } from '../AppSelect/AppSelect';
import styles from './SelectCommunityMembers.module.scss';
import { api } from '@lib/api';

export const SelectCommunityMembers = ({
  multiple,
  cardId,
  value,
  onChange,
  exclude = [],
  cardsIds,
  onlyMainCommunity = true,
}) => {
  const [defaultCards, setDefaultCards] = useState([]);

  const fetchCards = async () => {
    const cards = [];
    for (const cardId of cardsIds) {
      const { data } = await api.get(`/dashboard/club/cards/${cardId}`);
      cards.push(data);
    }
    return cards;
  };

  const findCards = async (search = '', isExclude = true) => {
    const response = await api.get(`/v2/dashboard/club/cards/`, {
      params: {
        page: 1,
        limit: 15,
        search,
        isArchive: false,
        onlyRegistered: true,
        onlyMainCommunity,
      },
    });
    const items = response.data.data;
    console.log('ITEMS', items);
    return items
      .filter((item) => {
        if (!isExclude) return true;
        return typeof exclude === 'function'
          ? !exclude(item)
          : !exclude.includes(item.id);
      })
      .map((item) => ({
        value: item,
        label: (
          <div className={styles.item}>
            <div className={styles.avatar}>
              <ResidentAvatar avatar={item.avatar} />
            </div>
            <div className={styles.details}>
              <div className={styles.name}>
                {item.firstName} {item.lastName}
              </div>
              <div className={styles.email}>
                {item.email || item.phone || item.telegram || `#${item.id}`}
              </div>
            </div>
          </div>
        ),
      }));
  };

  const init = async () => {
    const cards = await fetchCards();
    console.log('CARDS', cards);
    const items = cards.map((card) => {
      return {
        avatar: card.avatar,
        firstName: card.common.about.firstName,
        lastName: card.common.about.lastName,
        phone: card.common.contacts.phones[0],
        userId: card.userId,
        email: card.common.contacts.emails[0],
        telegram: card.common.contacts.telegrams[0],
      };
    });
    onChange(
      items.map((item) => ({
        value: item,
        label: (
          <div className={styles.item}>
            <div className={styles.avatar}>
              <ResidentAvatar avatar={item.avatar} />
            </div>
            <div className={styles.details}>
              <div className={styles.name}>
                {item.firstName} {item.lastName}
              </div>
              <div className={styles.email}>
                {item.email || item.phone || item.telegram || `#${item.id}`}
              </div>
            </div>
          </div>
        ),
      }))
    );
  };

  const initDefaultCards = async () => {
    const cards = await findCards();
    setDefaultCards(cards);
  };

  useEffect(() => {
    initDefaultCards();
  }, []);

  // const updateCardsIds = async () => {
  //   if (!cardsIds || !cardsIds.length) return;
  //   const selected = [];
  //   for (const cardId of cardsIds) {
  //     const items = await findCards(cardId, false);
  //     const item = items.find((item) => item.value.id === cardId);
  //     if (!item) continue;
  //     selected.push(item);
  //   }
  //   onChange(selected);
  // };

  useEffect(() => {
    init();
  }, [cardsIds]);

  return (
    <AppSelect
      value={value}
      defaultValue={value}
      isAsync
      loadOptions={findCards}
      defaultOptions={defaultCards}
      options={defaultCards}
      placeholder="Поиск по участникам..."
      noOptionsMessage="Ни одного участника не найдено"
      onChange={onChange}
      isMulti={multiple}
    />
  );
};
