import { ColorItem } from '../../../../components/ui/Colors/ColorItem/ColorItem';
import { CommunityAvatar } from '../CommunityAvatar/ComunityAvatar';
import styles from '../FormPreview.module.scss';
import { useSelector } from 'react-redux';
import { getGradientBackground } from '../../lib/background';
import { CommunityMembers } from '../CommunityMembers/CommunityMembers';
import { PAYMENT_PERIODS } from '../../../../data/constants';
import { plural } from '../../../../util/string';
import { formatPrice } from '../../../../util/format';
import { CommunityLinks } from '../../../community-links/components/CommunityLinks/CommunityLinks';

export const Preview = () => {
  const { title, slogan, website, telegram, about, bgColor, period, amount } =
    useSelector((state) => state.communityPage.data);
  const { community } = useSelector((state) => state.community);
  const {
    title: originalTitle,
    isPublic,
    isPaid,
    name,
  } = useSelector((state) => state.community.community);

  const getPaidLabel = () => {
    if (!isPaid) {
      return 'Бесплатное сообщество';
    }
    let periodLabel = PAYMENT_PERIODS.find(
      (option) => option.value === period
    )?.label;
    if (!periodLabel) {
      periodLabel =
        period + ' ' + plural(period, ['месяц', 'месяца', 'месяцев']);
    }
    return `${formatPrice(amount)}₽ / ${periodLabel}`;
  };

  return (
    <div
      className={styles.page}
      style={{ background: getGradientBackground(bgColor) }}
    >
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.avatarAndTitle}>
            <div className={styles.avatar}>
              <CommunityAvatar />
            </div>
            <div className={styles.title}>{title || originalTitle}</div>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subtitle}>Миссия</div>
            <div className={styles.subcontent + ' ' + styles.slogan}>
              {slogan || 'Не указано'}
            </div>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subtitle}>Сайт сообщества</div>
            <div className={styles.subcontent}>
              {community.url || 'Не указано'}
            </div>
          </div>
          <div className={styles.subsection}>
            <div className={styles.subtitle}>Адрес страницы</div>
            <div className={styles.subcontent + ' ' + styles.name}>
              {name
                ? process.env.REACT_APP_UNAUTH_REDIRECT.split('/lead/')[0] +
                  '/community/' +
                  name
                : 'Нет'}
            </div>
          </div>

          <div className={styles.subsection}>
            <div className={styles.subtitle}>Телеграм</div>
            <div className={styles.subcontent}>{telegram || 'Не указано'}</div>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>О нас</div>
          <div className={styles.subcontent}>{about || 'Не указано'}</div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Закрепленные ссылки</div>
          <div className={styles.content}>
            <CommunityLinks communityId={community.id} hideMeta />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Приватность</div>
          <div className={styles.subcontent}>
            {isPublic ? 'Открытое сообщество' : 'Закрытое сообщество'}
          </div>
        </div>

        {!isPublic && (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>Монетизация</div>
            <div className={styles.subcontent}>
              {!isPaid && 'Бесплатное сообщество'}
              {isPaid && getPaidLabel()}
            </div>
          </div>
        )}

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Цвет страницы</div>
          <div className={styles.subcontent}>
            <ColorItem color={bgColor} checked />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Команда сообщества</div>
          <div className={styles.subcontent}>
            <CommunityMembers editable={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
